import { Link } from '@reach/router';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { FullViewHeader as Header } from '../Common/FullViewHeader';
import { InnerHTML } from '../Common/InnerHTML';

export const StudyUpload = {
  status: {
    notExisting: 1,
    existingNotAvailable: 2,
    existingAvailable: 3,
    existingAlready: 4,
  },
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '1em 2em',
      width: '100%',
      maxWidth: 600,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  confirmation: {
    marginTop: '1em',
  },
  actions: {
    marginTop: '1.5em',
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 600,
  },
}));

export const UploadStudyConfirmationDialog = ({ data, upload, open, close }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const feedback = {
    owned: t(
      'study.upload.confirmation.info.owned',
      {
        name: data.title,
        id: data.studyInstanceUid,
      },
    ),
    notOwned: t(
      'study.upload.confirmation.info.not.owned',
      {
        name: data.title,
        id: data.studyInstanceUid,
      },
    ),
  };

  const updateStudy = () => { upload(data.studyInstanceUid); };

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
    >
      <Header title={t('study.confirm.upload')} />
      { data.haveInstance >= StudyUpload.status.existingAvailable && (
        <>
          <InnerHTML content={feedback.owned} />
          { data.hospital && (
            <ul>
              <li>
                Hospital:&nbsp;
                <b>{data.hospital.name}</b>
              </li>
            </ul>
          )}
          { data.medicalCase && (
            <ul>
              <li>
                Hospital:&nbsp;
                <b>{data.medicalCase.patient.hospital.name}</b>
              </li>
              <li>
                {`${t('patient')}: `}
                <b>{`${data.medicalCase.patient.name} ${data.medicalCase.patient.surname}`}</b>
              </li>
              <li>
                {`${t('case')}: `}
                <b>
                  {data.medicalCase.title}
                </b>
              </li>
              <li>
                {`${t('study')}: `}
                <Link to={`/study/${data.uuid}`}>
                  <b>{data.uuid}</b>
                </Link>
              </li>
            </ul>
          )}
        </>
      )}
      { data.haveInstance === StudyUpload.status.existingNotAvailable && (
        <InnerHTML content={feedback.notOwned} />
      )}
      <Typography className={styles.confirmation}>{t('study.upload.confirmation.message') }</Typography>
      <DialogActions className={styles.actions}>
        <Button
          variant="outlined"
          className={styles.button}
          onClick={close}
          color="primary"
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={styles.button}
          onClick={updateStudy}
          color="primary"
        >
          {t('update.study')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
